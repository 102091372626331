
import { Component, Vue } from 'vue-property-decorator'
import TheOffer from '@/components/offer/TheOffer.vue'

@Component({
  components: { TheOffer },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class OfferPage extends Vue {

}
